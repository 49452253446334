import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        items: [],
        customer: {},
        condition: "2",
    },
    reducers: {
        addArticle: (state, action) => {
            state.items.push(action.payload);
        },
        setCustomer: (state, action) => {
            state.customer = action.payload;
        },
        setCondition: (state, action) => {
            state.condition = action.payload;
        },
        deleteArticle: (state, action) => {
            state.items = state.items.filter(
                (item) => item.id !== action.payload
            );
        },
        cleanCart: (state) => {
            state.items = [];
            state.customer = {};
            state.condition = "";
        },
    },
});

export const {
    addArticle,
    setCustomer,
    deleteArticle,
    cleanCart,
    setCondition,
} = cartSlice.actions;

export const selectItems = (state) => state.cart.items;
export const selectCustomer = (state) => state.cart.customer;
export const selectCondition = (state) => state.cart.condition;

export default cartSlice.reducer;
