import React from 'react'
import {ListItem, ListItemText} from '@material-ui/core'


export const Article = ({nombre, id, onClick}) => {
	return (
    <ListItem button divider onClick={() => onClick(id)}>
		<ListItemText primary={nombre}></ListItemText>
	</ListItem>
	)
}
