import axios from "axios";
import { baseURL } from "./index";
import { normalize } from "normalizr";
import { articleList, customerList, orderList } from "./schema";
export const isSellerValid = (id) => {
    return axios.get(`${baseURL}/vendedores/valida/${id}`);
};

export const getCustomers = async (sellerID) => {
    const { data } = await axios.get(`${baseURL}/clientes/${sellerID}`);
    return normalize(data, customerList);
};

export const getArticles = async (query) => {
    const { data } = await axios.get(
        `${baseURL}/articulos?filtro=${query.toUpperCase()}`
    );
    return normalize(data, articleList);
};

export const saveOrder = (data) => {
    return axios.post(`${baseURL}/pedidos`, data);
};

export const getOrders = async(id) => {
    const { data } = await axios.get(`${baseURL}/pedidos/${id}`)
    return normalize(data, orderList);
}

export const deleteOrder = async(id) => {
    return  axios.delete(`${baseURL}/pedidos/${id}`)
}