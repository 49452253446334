import React, { useState } from "react";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import { validSeller, selectHasError, selectErrorMessage } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
   marginTop: theme.spacing(20),
  },
}));

export const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sellerId, setSellerId] = useState("");
  const handlerLogin = () => {
    dispatch(validSeller(sellerId));
  };
  const handleChangeSeller = (e) => {
    setSellerId(e.target.value);
  };
  const hasError = useSelector(selectHasError);
  const errorMessage = useSelector(selectErrorMessage);
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        spacing={3}
        justify="center"
        alignItems="center"
      >
        <Grid item zeroMinWidth>
          {hasError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorMessage}
            </Alert>
          )}
        </Grid>
        <Grid item zeroMinWidth>
          <TextField
            label="Codigo de Vendedor"
            defaultValue={sellerId}
            onChange={handleChangeSeller}
          />
        </Grid>
        <Grid item zeroMinWidth>
          <Button variant="contained" color="primary" onClick={handlerLogin}>
            INGRESAR
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
