import { createSlice } from "@reduxjs/toolkit";
import { isSellerValid } from "../../api/ordersAPI";

export const authSlica = createSlice({
    name: "auth",
    initialState: {
        sellerID: sessionStorage.getItem("sellerIdSipan"),
        isAuth:
            sessionStorage.getItem("sellerIdSipan") &&
            sessionStorage.getItem("sellerIdSipan") !== "",
        hasError: false,
        errorMessage: "",
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.sellerID = action.payload;
            state.isAuth = true;
            state.hasError = false;
            state.errorMessage = "";
            sessionStorage.setItem("sellerIdSipan", action.payload)
        },
        loginError: (state, action) => {
            state.errorMessage = action.payload;
            state.hasError = true;
            state.sellerID = "";
            state.isAuth = false;
            sessionStorage.setItem("sellerIdSipan", "")
        },
    },
});

export const { loginSuccess, loginError } = authSlica.actions;

export const validSeller = (id) => async (dispatch) => {
    try {
        await isSellerValid(id);
        dispatch(loginSuccess(id));
    } catch ({ response }) {
        if (response.status === 401) {
            const { mensaje } = response.data;
            dispatch(loginError(mensaje));
        }
    }
};

export const selectHasError = (state) => state.auth.hasError;
export const selectErrorMessage = (state) => state.auth.errorMessage;
export const selectIsAuth = (state) => state.auth.isAuth;
export const selectSellerID = (state) => state.auth.sellerID;

export default authSlica.reducer;
