import {
    AppBar,
    Toolbar,
    Typography,
    List,
    makeStyles,
    fade,
    InputBase,
    CircularProgress,
    Box,
    IconButton,
} from "@material-ui/core";
import React, { useEffect, useMemo, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSellerID } from "../auth/authSlice";
import { setCustomer } from "../cart/cartSlice";
import { useState } from "react";
//import { getCustomers } from "../../api/ordersAPIFake";
import { getCustomers } from "../../api/ordersAPI";
import { Customer } from "./Customer";
import { useHistory } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    search: {
        marginLeft: theme.spacing(2),
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        width: "100%",
    },
}));

export const CustomerList = memo(() => {
    const [customers, setCustomers] = useState({});
    const [customersIds, setCustomersIds] = useState([]);
    const [search, setSearch] = useState("");
    const sellerID = useSelector(selectSellerID);
    useEffect(() => {
        getCustomers(sellerID).then((data) => {
            if (data.entities?.customer) {
                setCustomers(data.entities.customer);
                setCustomersIds(data.result);
            }
        });

    }, [sellerID]);

    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const handleCustomer = useCallback((customer)=>{
            dispatch(setCustomer(customer));
            history.push("/orders/add");
    }, [dispatch, history])

    const customerFilters = useMemo(()=>
        customersIds.filter(id => {
        const customer = customers[id]
        return customer.nombre.includes(search.toUpperCase())
    }, )
    , [customers, customersIds, search])

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" edge="start" onClick={() => history.goBack()}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Typography variant="h6">Clientes</Typography>
                    <div className={classes.search}>
                        <InputBase
                            defaultValue=""
                            fullWidth
                            placeholder="Buscar Cliente"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            onKeyUp={(e)=> {
                                if(e.key === 'Enter' || e.target.value.length % 3 === 0) {
                                    setSearch(e.target.value)
                                }

                            } }
                        />
                    </div>
                </Toolbar>
            </AppBar>
                {customerFilters.length === 0 ? (
                    <Box margin="0 50%">
                        <CircularProgress />
                    </Box>
                )
                    :<List>
                {customerFilters.map((id) => {
                    let customer = customers[id];
                    return (
                        <Customer
                            key={id}
                            {...customer}
                            onCustomer={handleCustomer}
                        />
                    );
                })}
            </List>}
        </div>
    );
});
