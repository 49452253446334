import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Login } from "./features/auth/Login";
import { CustomerList } from "./features/customers/CustomerList";
import { useSelector } from "react-redux";
import { selectIsAuth } from "./features/auth/authSlice";
import { OrderAdd } from "./features/orders/OderAdd";
import { ArticleList } from "./features/orders/ArticleList";
import OrderList from "./features/orders/OrdersList";

function App() {
  const isAuth = useSelector(selectIsAuth);
  return (
    <div>
      <Router>
        <div>
          <Switch>
            <Route exact path="/">
              {isAuth ? <Redirect to="/orders" /> : <Login />}
            </Route>
            <Route exact path="/orders">
              {!isAuth ? <Redirect to="/" /> : <OrderList />}
            </Route>
            <Route exact path="/customers">
              {!isAuth ? <Redirect to="/" /> : <CustomerList />}
            </Route>
            <Route exact path="/orders/add">
              {!isAuth ? <Redirect to="/" /> : <OrderAdd />}
            </Route>
            <Route exact path="/orders/add/article">
              {!isAuth ? <Redirect to="/" /> : <ArticleList />}
            </Route>
          </Switch>
        </div>
      </Router>
    </div>
  );
}

export default App;
