import React from "react";
import {
    ListItem,
    ListItemText,
    Typography,
    ListItemSecondaryAction,
    IconButton,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

export const CartItem = ({
    id,
    name,
    quantity,
    greaterMeasure,
    minorMeasure,
    units,
    onDelete,
}) => {
    return (
        <ListItem dense divider>
            <ListItemText
                primary={name}
                secondary={
                    <div>
                        <Typography>
                            Cantidad: {quantity || "0"} {greaterMeasure}
                        </Typography>
                        <Typography>
                            Unidades: {units || "0"} {minorMeasure}
                        </Typography>
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => onDelete(id)}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </div>
                }
            />
        </ListItem>
    );
};
