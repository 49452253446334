import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Fab,
    makeStyles,
    IconButton,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
    Add as AddIcon,
    ArrowBack as ArrowBackIcon,
    Save as SaveIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { CartList } from "../cart/CartList";
import { selectItems, selectCustomer, cleanCart, selectCondition, setCondition } from "../cart/cartSlice";
import { selectSellerID } from "../auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { saveOrder } from "../../api/ordersAPI";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    condition: {
        margin: theme.spacing(2),
    },
    customer: {
        margin: theme.spacing(2),
    },
}));

export const OrderAdd = () => {
    const classes = useStyles();
    const history = useHistory();
    const items = useSelector(selectItems);
    const customer = useSelector(selectCustomer);
    const condition = useSelector(selectCondition)
    const sellerID = useSelector(selectSellerID);
    const [openSuccess, setOpenSucces] = useState(false);
    const dispatch = useDispatch();
    const handleSaveOrder = () => {
        const data = {
            clienteId: customer.id,
            condicionId: condition,
            vendedorId: sellerID,
            articulos: items.map((item, index) => ({
                articuloId: item.id,
                cantidad: Number(item.quantity),
                fraccion: Number(item.units),
                item: index + 1,
            })),
        };
        saveOrder(data).then(() => {
            dispatch(cleanCart());
            setOpenSucces(true);
            setTimeout(() => {
                history.push("/orders");
            }, 2000);
        });
    };

    return (
        <div>
            <Snackbar
                open={openSuccess}
                autoHideDuration={3000}
                onClose={setOpenSucces}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Alert severity="success" variant="filled">
                    Pedido Grabado
                </Alert>
            </Snackbar>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Nuevo Pedido
                    </Typography>
                    <Button
                        onClick={handleSaveOrder}
                        color="inherit"
                        disabled={openSuccess}
                        startIcon={<SaveIcon />}
                    >
                        Grabar
                    </Button>
                </Toolbar>
            </AppBar>
            <Typography variant="body1" className={classes.customer}>
                Cliente: {customer.name}
            </Typography>
            <FormControl component="fieldset" className={classes.condition}>
                <FormLabel component="legend">Condicion</FormLabel>
                <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue={condition}
                    onChange={(e) => {
                        dispatch(setCondition(e.target.value))
                    }}
                >
                    <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Contado"
                        value="2"
                    />
                    <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Credito"
                        value="1"
                    />
                </RadioGroup>
            </FormControl>
            <CartList items={items}></CartList>
            <Fab
                color="primary"
                className={classes.fab}
                onClick={() => {
                    history.push("/orders/add/article");
                }}
            >
                <AddIcon />
            </Fab>
        </div>
    );
};
