import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    InputBase,
    makeStyles,
    Button,
    fade,
    List,
    IconButton,
    Dialog,
    Paper,
    TextField,
    Slide,
    Grid,
    Typography,
    InputAdornment,
} from "@material-ui/core";

import {
    Search as SearchIcon,
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
//import { getArticles } from "../../api/ordersAPIFake";
import { getArticles } from "../../api/ordersAPI";
import { Article } from "./Article";
import { useHistory } from "react-router-dom";
import TextFieldNumber from "../../components/TextFieldNumber";
import { addArticle } from "../cart/cartSlice";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
    },
    dialog: {
        padding: theme.spacing(5),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ArticleList = () => {
    const classes = useStyles();
    const [articles, setArticles] = useState({});
    const [articlesIds, setArticlesIds] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [isEditQuantity, setEditQuantity] = useState(false);
    const [quantity, setQuantity] = useState("");
    const [units, setUnits] = useState("");
    const [query, setQuery] = useState("");
    const [articleId, setArticleId] = useState("");
    const handleSearhActicle = () => {
        setArticlesIds([]);
        setArticles({});
        getArticles(query).then((response) => {
            if (response?.entities.article) {
                setArticles(response.entities.article);
                setArticlesIds(response.result);
            }
        });
    };

    const dispatch = useDispatch();
    const handleAddCart = () => {
        const article = articles[articleId];
        dispatch(
            addArticle({
                id: article.id,
                name: article.nombre,
                greaterMeasure: article.unidad_mayor,
                minorMeasure: article.unidad_menor,
                quantity,
                units,
            })
        );
        history.goBack();
    };
    const history = useHistory();
    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <div className={classes.search}>
                        <InputBase
                            defaultValue={query}
                            onChange={(e) => setQuery(e.target.value)}
                            fullWidth
                            placeholder="Buscar Articulo"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                        />
                    </div>
                    <Button
                        onClick={handleSearhActicle}
                        color="inherit"
                        startIcon={<SearchIcon />}
                    >
                        Buscar
                    </Button>
                </Toolbar>
            </AppBar>
            <List>
                {articlesIds.map((id) => {
                    const article = articles[id];
                    return (
                        <Article
                            {...article}
                            key={id}
                            onClick={(id) => {
                                setArticleId(id);
                                setOpenDialog(true);
                            }}
                        />
                    );
                })}
            </List>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
            >
                <Paper className={classes.dialog}>
                    <Grid container direction="column" spacing={3}>
                        <Grid>
                            <Typography variant="h6">
                                {articleId && articles[articleId].nombre}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                disabled={isEditQuantity && units !== ""}
                                defaultValue={quantity}
                                label="Cantidad"
                                variant="outlined"
                                InputProps={{
                                    inputComponent: TextFieldNumber,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {articleId && articles[articleId].unidad_mayor}
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => {
                                    setQuantity(e.target.value);
                                    if (
                                        e.target.value === "" ||
                                        Number(e.target.value) > 0
                                    ) {
                                        setEditQuantity(false);
                                    } else {
                                        setEditQuantity(true);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                disabled={!isEditQuantity && quantity !== ""}
                                defaultValue={units}
                                label="Unidades"
                                variant="outlined"
                                InputProps={{
                                    inputComponent: TextFieldNumber,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {articleId && articles[articleId].unidad_menor}
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(e) => {
                                    setUnits(e.target.value);
                                    if (
                                        e.target.value === "" ||
                                        Number(e.target.value) > 0
                                    ) {
                                        setEditQuantity(true);
                                    } else {
                                        setEditQuantity(false);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddCart}
                            >
                                Agregar
                            </Button>
                            {"  "}
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setOpenDialog(false);
                                }}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
        </div>
    );
};
