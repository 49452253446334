import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import React from "react";

const ConfirmationDialog = ({ open, onClose, order }) => {
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={open}
        >
            <DialogTitle>
            <Typography color="error" variant="h6" component="p">Eliminar Pedido</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>Desea eliminar Pedido de {order.customer_name}?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={()=>onClose(true)}>Aceptar</Button>
                <Button onClick={()=>onClose()}>Cancelar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
