import React, {memo} from "react";
import { Typography, Grid, ListItem, Paper } from "@material-ui/core";

export const Customer = memo(({
    id,
    nombre,
    tipo_documento,
    nro_documento,
    direccion,
    onCustomer,
}) => {
    return (
        <Paper>
            <ListItem
                button
                dense
                divider
                onClick={() => {
                    onCustomer({
                        id,
                        name: nombre,
                    });
                }}
            >
                <Grid container direction="column">
                    <Grid item zeroMinWidth>
                        <Typography variant="button" noWrap>
                            {nombre}
                        </Typography>
                    </Grid>
                    <Grid item zeroMinWidth>
                        <Typography variant="caption">
                            {tipo_documento}: {nro_documento}
                        </Typography>
                    </Grid>
                    <Grid item zeroMinWidth>
                        <Typography variant="caption">{direccion}</Typography>
                    </Grid>
                </Grid>
            </ListItem>
        </Paper>
    );
});
