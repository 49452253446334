import React from "react";
import { List } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { deleteArticle } from "./cartSlice";
import { CartItem } from "./CartItem";

export const CartList = ({ items }) => {
    const dispatch = useDispatch();
    return (
        <div>
            <List>
                {items.map((item) => (
                    <CartItem
                        {...item}
                        onDelete={(id) => {
                            dispatch(deleteArticle(id));
                        }}
                        key={item.id}
                    />
                ))}
            </List>
        </div>
    );
};
