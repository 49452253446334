import {
    AppBar,
    Fab,
    List,
    makeStyles,
    Toolbar,
    Typography,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteOrder, getOrders } from "../../api/ordersAPI";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { selectSellerID } from "../auth/authSlice";
import OrderItem from "./OrderItem";

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const OrderList = () => {
    const [orderIds, setOrderIds] = useState([]);
    const [orders, setOrders] = useState({});
    const [order, setOrder] = useState({});
    const sellerId = useSelector(selectSellerID);
    const classes = useStyles();
    const history = useHistory();
    const loadOrders = useCallback(()=>{
        setOrders({});
        setOrderIds([]);
        getOrders(sellerId).then((data) => {
            if (data.entities?.order) {
                setOrders(data.entities.order);
                setOrderIds(data.result);
            }
        });
    }, [sellerId])
    useEffect(() => {
        loadOrders()
    }, [loadOrders]);

    const [openDelete, setOpenDelete] = useState(false)

    const handleDelete =  (selectedOrder) => {
        setOrder(selectedOrder)
        setOpenDelete(true)
    }

    const handleClose = (confirm) => {
        setOpenDelete(false)
        if(confirm) {
            deleteOrder(order.id).then(()=>{
                loadOrders()
            })
        }
    }

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>
                    <Typography variant="h6">Pedidos</Typography>
                </Toolbar>
            </AppBar>
            <List>
                {orderIds.length > 0 &&
                    orderIds.map((id) => {
                        const order = orders[id];
                        return <OrderItem key={id} {...order} onDelete={handleDelete} />;
                    })}
            </List>
            <Fab
                color="primary"
                className={classes.fab}
                onClick={() => {
                    history.push("/customers");
                }}
            >
                <AddIcon />
            </Fab>
            <ConfirmationDialog order={order} open={openDelete} onClose={handleClose}/>
        </div>
    );
};
export default OrderList;
