import {
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import React from "react";

const OrderItem = (props) => {
    const { customer_name, customer_address, id, onDelete } = props;

    return (
        <ListItem divider button>
            <ListItemText
                primary={
                    <Typography variant="button" component="p" noWrap>
                        {customer_name}
                    </Typography>
                }
                secondary={
                    <Typography component="p" noWrap variant="caption">
                        {customer_address}
                    </Typography>
                }
            />
            <ListItemSecondaryAction>
                <IconButton onClick={() => onDelete({ id, customer_name })}>
                    <DeleteIcon color="error" />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default OrderItem;
